import React from "react"

import {
  Text,
  TextType,
  TextFontSize,
  TextFontWeight,
} from "../components/Text"

import "./page.styles.scss"

const CookiePolicy = () => {
  return (
    <div className={"page"}>
      <Text
        type={TextType.H1}
        size={TextFontSize.huge}
        weight={TextFontWeight.bold}
      >
        Cookie Policy
      </Text>

      <Text type={TextType.PARAGRAPH}>
        HelloJS (hello-js.com) website is owned by Michał Rożenek (“HelloJS”,
        “we”, “us”, and “our”).
      </Text>
      <Text type={TextType.PARAGRAPH}>
        This Cookie Policy explains how we use cookies on this website.
      </Text>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        What are cookies?
      </Text>
      <Text type={TextType.PARAGRAPH}>
        Cookies are small data files that are placed on your computer or mobile
        device when you visit a website. Cookies are used to make websites or
        services work properly, or work more efficiently, as well as to provide
        reporting and analytics information.
      </Text>
      <Text type={TextType.PARAGRAPH}>
        Cookies set by hello-js.com are called “first-party cookies”. Cookies
        set by parties other than our website are called “third-party cookies”.
        Third-party cookies enable third-party features or functionality to be
        provided on or through the website or service you are using (analytics,
        newsletter etc.).
      </Text>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        Why do we use cookies and tracking technologies?
      </Text>
      <Text type={TextType.PARAGRAPH}>
        We would like to know who is reading our blog so that we can provide the
        most interesting content to the hello-js.com users and improve the
        website performance and usability.
      </Text>
      <Text type={TextType.PARAGRAPH}>
        To be able to do that we use Google Analytics tool which stores
        third-party cookies on users end devices and prepares the analytics data
        for our needs.
      </Text>

      <Text type={TextType.PARAGRAPH}>
        The important information we can read from Google Analytics are:
      </Text>
      <ul className={"page__list"}>
        <li>How long time users spend on our website</li>
        <li>
          Users devices data such as IP address, operating system, browser, screen
          resolution, device type etc.
        </li>
        <li>User location (country and the city)</li>
        <li>Information about the most interesting content to read</li>
      </ul>
      <Text type={TextType.PARAGRAPH}>
        And the other kind of data which analysis tools collect for us.
      </Text>
      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        Contact
      </Text>
      <Text type={TextType.PARAGRAPH}>
        In case of any questions or concerns regarding this Policy or the
        practices of this Website, we encourage you to contact us at:
      </Text>
      <Text type={TextType.PARAGRAPH}>
        Michał Rożenek
        <br />
        contact.hellojs@gmail.com
        <br />
        <br />
        <i>Last updated on 03/01/2023</i>
      </Text>
    </div>
  )
}

export default CookiePolicy
